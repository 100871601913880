import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { analyticsEvents } from '~/res/constants/events'
import { surveyIds } from '~/res/constants/surveys'
import { trackEvent } from '~/utils/analytics/events'

type SurveyDialogContextType = {
  isDialogOpen: boolean
  openSurveyDialog: (surveyId?: keyof typeof surveyIds) => void
  closeSurveyDialog: () => void
  isThanksToastOpen: boolean
  openThanksToast: () => void
  closeThanksToast: () => void
  surveyId: string | null
  setSurveyId: (surveyId: string | null) => void
}

export const SurveyDialogContext =
  createContext<SurveyDialogContextType | null>(null)

export const SurveyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [surveyId, setSurveyId] = useState<string | null>(null)
  const [isThanksToastOpen, setIsThanksToastOpen] = useState(false)

  const openSurveyDialog = useCallback(
    (newSurveyId?: keyof typeof surveyIds) => {
      if (newSurveyId) {
        setSurveyId(surveyIds[newSurveyId])
      }

      setIsDialogOpen(true)
      trackEvent({
        name: 'click_interaction',
        label: analyticsEvents.csat.open,
        interaction_result: surveyId ?? 'unknown',
      })
    },
    [surveyId],
  )

  const closeSurveyDialog = () => {
    setIsDialogOpen(false)
  }

  const openThanksToast = () => {
    setIsThanksToastOpen(true)
  }

  const closeThanksToast = () => {
    setIsThanksToastOpen(false)
  }

  const providerValue = useMemo(() => {
    return {
      isDialogOpen,
      openSurveyDialog,
      closeSurveyDialog,
      isThanksToastOpen,
      openThanksToast,
      closeThanksToast,
      surveyId,
      setSurveyId,
    }
  }, [isDialogOpen, isThanksToastOpen, openSurveyDialog, surveyId])

  return (
    <SurveyDialogContext.Provider value={providerValue}>
      {children}
    </SurveyDialogContext.Provider>
  )
}

export const useSurveyDialog = () => {
  const context = useContext(SurveyDialogContext)

  if (!context) {
    throw new Error('useSurveyDialog must be used inside the SurveyProvider')
  }

  return context
}
